exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-developer-js": () => import("./../../../src/pages/app-developer.js" /* webpackChunkName: "component---src-pages-app-developer-js" */),
  "component---src-pages-app-development-js": () => import("./../../../src/pages/app-development.js" /* webpackChunkName: "component---src-pages-app-development-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-checklist-choosing-the-right-developer-js": () => import("./../../../src/pages/checklist-choosing-the-right-developer.js" /* webpackChunkName: "component---src-pages-checklist-choosing-the-right-developer-js" */),
  "component---src-pages-ebook-coming-up-with-an-app-idea-js": () => import("./../../../src/pages/ebook-coming-up-with-an-app-idea.js" /* webpackChunkName: "component---src-pages-ebook-coming-up-with-an-app-idea-js" */),
  "component---src-pages-health-fitness-app-developer-js": () => import("./../../../src/pages/health-fitness-app-developer.js" /* webpackChunkName: "component---src-pages-health-fitness-app-developer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-industries-js": () => import("./../../../src/pages/our-industries.js" /* webpackChunkName: "component---src-pages-our-industries-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-tech-for-businesses-js": () => import("./../../../src/pages/tech-for-businesses.js" /* webpackChunkName: "component---src-pages-tech-for-businesses-js" */),
  "component---src-pages-tech-for-ctos-js": () => import("./../../../src/pages/tech-for-ctos.js" /* webpackChunkName: "component---src-pages-tech-for-ctos-js" */),
  "component---src-pages-tech-for-founders-js": () => import("./../../../src/pages/tech-for-founders.js" /* webpackChunkName: "component---src-pages-tech-for-founders-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank_you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thanks-for-subscribing-checklist-js": () => import("./../../../src/pages/thanks-for-subscribing-checklist.js" /* webpackChunkName: "component---src-pages-thanks-for-subscribing-checklist-js" */),
  "component---src-pages-thanks-for-subscribing-ebook-js": () => import("./../../../src/pages/thanks-for-subscribing-ebook.js" /* webpackChunkName: "component---src-pages-thanks-for-subscribing-ebook-js" */),
  "component---src-templates-blog-inner-blog-inner-js": () => import("./../../../src/templates/blog_inner/blog_inner.js" /* webpackChunkName: "component---src-templates-blog-inner-blog-inner-js" */),
  "component---src-templates-case-study-inner-case-study-inner-js": () => import("./../../../src/templates/case_study_inner/case_study_inner.js" /* webpackChunkName: "component---src-templates-case-study-inner-case-study-inner-js" */),
  "component---src-templates-seo-pages-page-js": () => import("./../../../src/templates/seo_pages/page.js" /* webpackChunkName: "component---src-templates-seo-pages-page-js" */)
}

